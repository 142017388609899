/* ==========================================================================
   Fonts - licensed from Fontspring on 2018-01-30
   ========================================================================== */

@font-face {
    font-family: 'brandongrotesque-bold';
    src: url('../fonts/hvd_fonts_-_brandongrotesque-bold-webfont.woff2') format('woff2'),
         url('../fonts/hvd_fonts_-_brandongrotesque-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'brandongrotesque-medium';
    src: url('../fonts/hvd_fonts_-_brandongrotesque-medium-webfont.woff2') format('woff2'),
         url('../fonts/hvd_fonts_-_brandongrotesque-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'brandongrotesque-mediumitalicbold';
    src: url('../fonts/hvd_fonts_-_brandongrotesque-mediumitalic-webfont.woff2') format('woff2'),
         url('../fonts/hvd_fonts_-_brandongrotesque-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
