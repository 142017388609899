.footer {
  align-content: center;
  display: flex;
  font-family: 'brandongrotesque-bold';
  flex-direction: column;
  justify-content: space-around;
  height: 80px;
  width: 100vw;
  p {
    align-self: center;
    color: $basetext;
    letter-spacing: 0.09375em;
    text-align: center;
    text-transform: uppercase;
    a {
        color: $basetext;
        display: block;
        text-decoration: none;
        @media only screen and (min-width:768px) {
            border-left: 1px dotted $basetext;
            display: inline;
            margin-left: 1em;
            padding-left: 1em;
        }
    }
  }
}
