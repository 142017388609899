.header {
  display: flex;
  align-content: center;
  flex-direction: row;
  justify-content: space-around;
  min-height: calc(100vh - 80px);
  text-align: center;
  width: 100vw;
}
.logo {
  align-self: center;
  display: block;
  margin: 0 auto;
  width: 80vw;
  @media only screen and (min-width:768px) {
    width: 60vw;
  }
  @media only screen and (min-width:1024px) {
    width: 50vw;
  }
  @media only screen and (min-width:1140px) {
    width: 46vw;
  }
  img {
    // display: block;
    margin: 0 auto;
    // height: auto;
    // width: 100%;
  }
  p {
    color: $basetext;
    font-family: 'brandongrotesque-bold';
    font-size: 1.75em;
    letter-spacing: 0.09375em;
    text-transform: uppercase;
  }
}


